import React from "react"
import {FormattedMessage, Link, useIntl} from "gatsby-plugin-intl"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {Row, Col, Card, Divider, Button, Carousel} from "antd";
import logo from "../../assets/images/sp/sp-logo-11.svg"
import {DownloadOutlined, LinkOutlined} from "@ant-design/icons";
import lamone from "../../assets/images/sp/11/lamone.jpg";
import balerna from "../../assets/images/sp/11/balerna.jpg";

const SponsorPage = () => {
    const intl = useIntl()
    return (
        <Layout>
            <SEO
                lang={intl.locale}
                title={`BMW Emil Frey | Sponsor | ${intl.formatMessage({ id: "title" })}`}
                keywords={["wuerth, wuerth open, open lugano, golf, wuerth golf open, golf lugano"]}
            />
            <div className="page-sponsor-detail">
                <div className="container text-center pt-4">
                    <h6 className="text-uppercase text-primary">Sponsor Würth Open 2021</h6>
                    <h4 className="text-uppercase fw-bold">Buca 11</h4>
                    <img src={logo} alt="Logo" className="sp-logo"/><br/>
                    <a href="https://www.emilfrey.ch" target="_blank">
                        <Button type="primary" icon={<LinkOutlined/>} className="my-3">Sito ufficiale</Button>
                    </a>
                </div>
                <section className="bg-secondary">
                    <div className="container">
                        <Row gutter={{xs: 16, lg: 48}} align="top">
                            <Col xs={{span: 24, order: 1}} lg={{span: 12, order: 2}} className="text-justify">
                                <h6 className="text-primary text-uppercase fw-bold">SEDE DI</h6>
                                <h2>Lamone</h2>
                                <p>
                                    <b>Indirizzo</b> <br/>
                                    Via Vedeggio 12, 6814 Lamone <br/><br/>
                                    <b>Telefono</b> <br/>
                                    +41 (0) 91 961 63 63 <br/><br/>
                                    <b>E-mail</b> <br/>
                                    <a href="mailto:lamone@emilfrey.ch">lamone@emilfrey.ch</a>
                                </p>
                                <a href="https://dealer.bmw.ch/efsa-lamone/it" target="_blank">
                                    <Button type="primary" icon={<LinkOutlined/>} className="my-3">Sito ufficiale</Button>
                                </a>
                            </Col>
                            <Col xs={{span: 24, order: 2}} lg={{span: 12, order: 1}} className="text-justify">
                                <img src={lamone} alt="BMW Emil Frey Lamone"
                                     className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                            </Col>
                        </Row>
                    </div>
                    <div className="container">
                        <Divider/>
                        <Row gutter={{xs: 16, lg: 48}} align="top" className="pt-4">
                            <Col xs={{span: 24, order: 1}} lg={{span: 12, order: 2}} className="text-justify">
                                <h6 className="text-primary text-uppercase fw-bold">SEDE DI</h6>
                                <h2>Balerna</h2>
                                <p>
                                    <b>Indirizzo</b> <br/>
                                    Via San Gottardo 6, 6828 Balerna <br/><br/>
                                    <b>Telefono</b> <br/>
                                    +41 (0) 91 610 15 00 <br/><br/>
                                    <b>E-mail</b> <br/>
                                    <a href="mailto:info-balerna@emilfrey.ch">info-balerna@emilfrey.ch</a>
                                </p>
                                <a href="https://dealer.bmw.ch/efsa-balerna/it" target="_blank">
                                    <Button type="primary" icon={<LinkOutlined/>} className="my-3">Sito ufficiale</Button>
                                </a>
                            </Col>
                            <Col xs={{span: 24, order: 2}} lg={{span: 12, order: 1}} className="text-justify">
                                <img src={balerna} alt="BMW Emil Frey Balerna"
                                     className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section>
                    <div className="container text-center">
                        <h3>I nuovi modelli BMW da Emil Frey Lamone e Balerna</h3>
                        <div className="youtubeContainer shadow">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/Om6h5pqGeww"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen/>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default SponsorPage
